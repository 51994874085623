import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { uniqueId } from "../../utils/uniqueId";

type FieldsetProps = React.PropsWithChildren<
  React.BaseHTMLAttributes<HTMLDivElement> & {
    legend?: string;
    autofocus?: boolean;
  }
>;

export const Fieldset = ({
  children,
  legend,
  autofocus = false,
  ...props
}: FieldsetProps): React.ReactElement => {
  const id = useMemo(() => uniqueId("fieldset_"), []);
  const [shouldAutofocus] = useState(() => autofocus);
  const legendRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldAutofocus && legendRef.current) {
      legendRef.current.focus();
    }
  }, [shouldAutofocus]);

  return (
    <FieldsetWrapper role="group" aria-labelledby={id} {...props}>
      {legend && (
        <Legend
          ref={legendRef}
          tabIndex={-1}
          role="heading"
          aria-level={3}
          id={id}
        >
          {legend}
        </Legend>
      )}
      {children}
    </FieldsetWrapper>
  );
};

const FieldsetWrapper = styled.div`
  border: 0;
  padding: 0;
  margin: 30px 0 20px 0;
`;

const Legend = styled.div`
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 20px;
`;
