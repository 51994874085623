import React from "react";
import { uniqueId } from "../../utils/uniqueId";
import { TextFieldWrapper } from "../TextFieldWrapper";

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  options: Array<{
    label: string;
    value: string;
  }>;
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, options, ...props }: SelectProps, ref) => {
    const id = props.id || uniqueId("select_");

    return (
      <TextFieldWrapper id={id} label={label} required={props.required}>
        <select {...props} ref={ref} id={id}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </TextFieldWrapper>
    );
  }
);

Select.displayName = "Select";
