import { env } from './env';

export type PathToLangMap = { [path: string]: string; };

export const languages = {
  fi: "Finnish",
  sv: "Svenska",
  en: "English",
};

export const pathToLanguage: PathToLangMap = {
  '/yhteydenotto': 'fi',
  '/kontakt': 'sv',
  '/contact': 'en',
};

export const API_URL = env.REACT_APP_API_URL;

export const RECAPTCHA_KEY = env.REACT_APP_RECAPTCHA_KEY;
