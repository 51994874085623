import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Color from "../../utils/colors";

type TextFieldWrapperProps = React.PropsWithChildren<{
  id: string;
  label?: string;
  errorText?: string;
  required?: boolean;
}>;

export const TextFieldWrapper = ({
  id,
  label,
  errorText,
  required,
  children,
}: TextFieldWrapperProps): React.ReactElement => {
  const { t } = useTranslation();

  const errorId = `${id}_error`;

  return (
    <TextInputWrapperElement>
      {label && (
        <Label htmlFor={id} className={errorText ? "invalid" : undefined}>
          {label}
          {required && <span>{t("required")}</span>}
        </Label>
      )}
      <div>{children}</div>
      {errorText && <ErrorText id={errorId}>{errorText}</ErrorText>}
    </TextInputWrapperElement>
  );
};

const TextInputWrapperElement = styled.div`
  margin-bottom: 20px;

  > div {
    position: relative;
    display: flex;

    &:focus-within {
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid ${Color.Blue};
        pointer-events: none;
      }
    }
  }

  input,
  select,
  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid ${Color.DarkGrey};
    outline: none;
    position: relative;
    padding: 14px 10px;
    margin: 0;

    &.invalid {
      border-color: ${Color.Error};
    }

    &:focus {
      border-color: ${Color.Blue};
    }
  }

  textarea {
    height: 10rem;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 1.6rem;
  margin-bottom: 5px;

  &.invalid {
    color: ${Color.Error};
  }

  span {
    float: right;
    color: ${Color.Magenta};
    font-size: 1.4rem;
  }
`;

const ErrorText = styled.div`
  color: ${Color.Error};
  margin-top: 5px;
`;
