import React from "react";
import styled from "styled-components";
import Color from "../../utils/colors";
import { focusOutline } from "../../utils/accessibility";
import { Spinner } from "../Spinner";
import { classNames } from "../../utils/classNames";

type ButtonProps = React.PropsWithChildren<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: "primary" | "secondary";
    isLoading?: boolean;
    loadingText?: string;
  }
>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = "primary",
      type = "button",
      isLoading = false,
      loadingText,
      disabled,
      ...props
    }: ButtonProps,
    ref
  ) => {
    return (
      <ButtonElement
        className={classNames(variant, disabled && "disabled")}
        ref={ref}
        type={type}
        {...props}
        disabled={disabled || isLoading}
        aria-disabled={disabled || isLoading}
        aria-label={isLoading && loadingText ? loadingText : undefined}
      >
        {isLoading && <Spinner className="ButtonSpinner" />}
        {isLoading && loadingText ? loadingText : children}
      </ButtonElement>
    );
  }
);

Button.displayName = "Button";

const ButtonElement = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
  padding: 15px 30px;

  cursor: pointer;

  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;

  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  --color: ${Color.Blue};

  .ButtonSpinner {
    margin-right: 15px;
    --spinner-size: 20px;
    --spinner-thickness: 3px;
  }

  &.disabled {
    cursor: not-allowed;
    --color: ${Color.DarkGrey};
  }

  &.primary {
    background-color: var(--color);
    color: #fff;
    .ButtonSpinner {
      --spinner-color: #fff;
    }
  }

  &.secondary {
    color: var(--color);
  }

  &:focus,
  &:active {
    ${focusOutline}
  }
`;
