import React from "react";
import { uniqueId } from "../../utils/uniqueId";
import { TextFieldWrapper } from "../TextFieldWrapper";

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, ...props }: TextAreaProps, ref) => {
    const id = props.id || uniqueId("textArea_");

    return (
      <TextFieldWrapper id={id} label={label} required={props.required}>
        <textarea ref={ref} id={id} {...props} />
      </TextFieldWrapper>
    );
  }
);

TextArea.displayName = "TextArea";
