import { css } from "styled-components";
import Color from "./colors";

export const visuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const focusOutline = css`
  outline: none;
  position: relative;

  &:focus,
  &:active {
    &::after {
      content: "";
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      border: 2px solid ${Color.Blue};
    }
  }
`;
