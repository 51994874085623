import styled from "styled-components";
import { Device } from "../../utils/breakpoints";

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-bottom: 10px;
  }

  @media (${Device.Medium}) {
    flex-direction: row-reverse;
    justify-content: space-between;

    > button {
      margin-bottom: 0;
    }
  }
`;
