import React from "react";
import styled from "styled-components";
import Color from "../../utils/colors";

type SpinnerProps = {
  className?: string;
};

export const Spinner = ({ className }: SpinnerProps): React.ReactElement => {
  return (
    <SpinnerWrapper className={className}>
      <div />
      <div />
      <div />
    </SpinnerWrapper>
  );
};

const SpinnerWrapper = styled.div`
  --spinner-size: 100px;
  --spinner-thickness: 10px;
  --spinner-duration: 2s;
  --spinner-color: ${Color.Blue};

  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-top-color: var(--spinner-color);

  div {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: var(--spinner-thickness) solid transparent;
    border-top-color: inherit;
    border-radius: 50%;
    animation: spinner-rotation-animation var(--spinner-duration)
      cubic-bezier(0.6, 0.2, 0.4, 0.8) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.15s;
  }
  div:nth-child(3) {
    animation-delay: -0.3s;
  }

  @keyframes spinner-rotation-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
