import { ExceptionResponse } from "../types/types";

export const isValidEmail = (value: string): boolean => {
  return /^.+@.+\..+$/.test(value);
};

export const isValidLength = (
  value: string,
  min?: number,
  max?: number
): boolean => {
  const length = value.trim().length;
  
  if (min && length < min) {
    return false;
  }

  if (max && length > max) {
    return false;
  }
  
  return true;
}

export const getContactInformationErrors = (
  rows: ExceptionResponse["errors"]
): { [key: string]: string } => {
  return Object.entries(rows || {})
    .filter(([field]) => /^contact\./.test(field))
    .reduce((acc, [field, message]) => {
      return {
        ...acc,
        [field.replace(/^contact\./, "")]:
          message
            .replace(field, "{{fieldname}}")
            .replace('""{{fieldname}}""', '"{{fieldname}}"'),
      };
    }, {});
};
