/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, useCallback, useEffect } from "react";
import { getRegions } from "../api/regions";
import { Answer, ContactInformation, Region } from "../types/types";
import { stripTags } from "../utils/stripTags";

export enum FormState {
  Questions,
  ContactInfo,
  Success,
}

type ContactInformationLengths = Record<
  keyof ContactInformation,
  { min?: number, max?: number } | undefined
>;

type FormContextState = {
  formState: FormState;
  answers: Answer[];
  contactInformation: ContactInformation;
  contactInformationLengths: ContactInformationLengths;
  regions: Region[];
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  setAnswer: (answer: Answer) => void;
  setContactInformationField: (
    field: keyof ContactInformation,
    value?: string | boolean
  ) => void;
};

const formContextDefaultValues: FormContextState = {
  formState: FormState.Questions,
  answers: [],
  contactInformation: {
    noBusinessId: false,
    businessId: undefined,
    companyName: undefined,
    area: undefined,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  contactInformationLengths: {
    noBusinessId: undefined,
    businessId: { min: 5 },
    companyName: { min: 1, max: 100 },
    area: { min: 1, max: 100 },
    firstName: { min: 1, max: 50 },
    lastName: { min: 1, max: 50 },
    email: { min: 1, max: 100 },
    phoneNumber: { min: 1, max: 50 }
  },
  regions: [],
  setFormState: () => {},
  setAnswer: () => {},
  setContactInformationField: () => {},
};

export const FormContext = createContext<FormContextState>(
  formContextDefaultValues
);

export const FormContextProvider = ({
  children,
  defaultValues = {},
}: React.PropsWithChildren<{
  defaultValues?: Partial<FormContextState>;
}>): React.ReactElement => {
  const initialValues = { ...formContextDefaultValues, ...defaultValues };

  const [answers, setAnswers] = useState<Answer[]>(initialValues.answers);
  const [formState, setFormState] = useState<FormState>(
    initialValues.formState
  );
  const [contactInformation, setContactInformation] =
    useState<ContactInformation>(initialValues.contactInformation);
  const [contactInformationLengths] =
    useState<ContactInformationLengths>(initialValues.contactInformationLengths);
  const [regions, setRegions] = useState<Region[]>(initialValues.regions);

  const setAnswer = useCallback(
    (newAnswer: Answer) => {
      // Check if this page has already been answered
      const oldAnswerIndex = answers.findIndex(
        (answer) => answer.pageId === newAnswer.pageId
      );

      // If there's already an answer for this page, update the answer
      if (oldAnswerIndex !== -1) {
        // If this answer changes the next page id, we should remove
        // all answers after this current page
        const removeQuestionsAfterThis =
          answers[oldAnswerIndex].nextPage !== newAnswer.nextPage;

        setAnswers((previousAnswers) =>
          previousAnswers
            .map((previousAnswer, index) => {
              if (index === oldAnswerIndex) {
                return newAnswer;
              }
              return previousAnswer;
            })
            .filter((_answer, index) => {
              if (removeQuestionsAfterThis) {
                return index <= oldAnswerIndex;
              }
              return true;
            })
        );
      }
      // If there's no previous answer, just append the new answer to the list of answers
      else {
        setAnswers((previousAnswers) => [...previousAnswers, newAnswer]);
      }
    },
    [answers]
  );

  const setContactInformationField = useCallback(
    (field: keyof ContactInformation, value?: string | boolean) => {
      setContactInformation({
        ...contactInformation,
        [field]: typeof value === "string" ? stripTags(value) : value,
      });
    },
    [contactInformation]
  );

  useEffect(() => {
    getRegions().then((regionsResponse) => {
      setRegions(regionsResponse.regions);
    });
  }, []);

  return (
    <FormContext.Provider
      value={{
        formState,
        answers,
        contactInformation,
        contactInformationLengths,
        regions,
        setFormState,
        setAnswer,
        setContactInformationField,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
