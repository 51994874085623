import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { focusOutline } from "../../utils/accessibility";
import Color from "../../utils/colors";
import { uniqueId } from "../../utils/uniqueId";
import angleUpIcon from "../../assets/angle-up-light.svg";
import { Device } from "../../utils/breakpoints";
import { TextArea } from "../TextArea";

type RadioButtonProps = React.PropsWithChildren<
  React.InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    requireUserInput?: boolean;
    userInputLabel?: string;
    userInputDefaultValue?: string;
    onChange?: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      userInput?: string
    ) => void;
  }
>;

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      children,
      label,
      requireUserInput = false,
      userInputLabel,
      userInputDefaultValue,
      onChange,
      ...props
    }: RadioButtonProps,
    ref
  ) => {
    const { t } = useTranslation();
    const id = props.id || uniqueId("radio_");
    const labelId = `${id}_label`;
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>();

    const toggleExpanded: React.MouseEventHandler = (event) => {
      event.preventDefault();
      setIsExpanded(!isExpanded);
    };

    const onRadioChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      if (onChange && typeof onChange === "function") {
        onChange(event, userInput);
      }
    };

    const onTextareaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
      event
    ) => {
      const { value } = event.target;
      setUserInput(value);
      if (onChange && typeof onChange === "function") {
        onChange(event, value);
      }
    };

    return (
      <RadioButtonWrapper>
        <RadioButtonControl>
          <label htmlFor={id} id={labelId}>
            {label}
          </label>
          <input
            {...props}
            onChange={onRadioChange}
            id={id}
            ref={ref}
            type="radio"
          />
        </RadioButtonControl>
        {children && (
          <InfoWrapper>
            <ToggleInfoButtonWrapper
              className={isExpanded ? "--expanded" : undefined}
            >
              <ToggleInfoButton
                aria-expanded={isExpanded}
                onClick={toggleExpanded}
              >
                {t(isExpanded ? "minimize" : "read-more")}
                <img src={angleUpIcon} aria-hidden alt="" />
              </ToggleInfoButton>
            </ToggleInfoButtonWrapper>
            <InfoText
              role="region"
              aria-labelledby={labelId}
              style={{ display: isExpanded ? "block" : "none" }}
            >
              {children}
            </InfoText>
          </InfoWrapper>
        )}
        {requireUserInput && (
          <UserInput style={{ display: props.checked ? "block" : "none" }}>
            <TextArea
              label={userInputLabel}
              onChange={onTextareaChange}
              defaultValue={userInputDefaultValue}
            />
          </UserInput>
        )}
      </RadioButtonWrapper>
    );
  }
);

RadioButton.displayName = "RadioButton";

const CHECKBOX_SIZE = 20;
const CHECKBOX_LABEL_SPACING = 10;

const RadioButtonWrapper = styled.div`
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  padding: 15px 10px;
  position: relative;

  &:focus-within {
    border-color: ${Color.Blue};
  }
`;

const RadioButtonControl = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media (${Device.Medium}) {
    padding-right: 10rem;
  }

  input {
    flex-grow: 0;
    flex-shrink: 0;
    width: ${CHECKBOX_SIZE}px;
    height: ${CHECKBOX_SIZE}px;
    margin-right: ${CHECKBOX_LABEL_SPACING}px;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
`;

const ToggleInfoButtonWrapper = styled.div`
  text-align: right;
  margin-top: 15px;

  @media (${Device.Medium}) {
    position: absolute;
    top: -20px;
    right: 15px;
    margin-top: 0;
  }

  &.--expanded {
    position: absolute;
    top: auto;
    right: 15px;
    bottom: 0px;
  }
`;

const ToggleInfoButton = styled.button`
  appearance: none;
  border: 0;
  color: ${Color.Blue};
  background: transparent;
  padding: 0;
  text-align: right;
  outline: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:active {
    ${focusOutline}
  }

  img {
    height: 2rem;
    width: auto;
    margin-left: 5px;
    transform: rotate(180deg);
  }

  &[aria-expanded="true"] {
    img {
      transform: rotate(0);
    }
  }
`;

const InfoText = styled.div`
  padding-bottom: 20px;
  padding-left: ${CHECKBOX_SIZE + CHECKBOX_LABEL_SPACING}px;
`;

const UserInput = styled.div`
  margin-top: 20px;
`;
