import { Answer, ExceptionResponse, RecipientRequest, RecipientResponse } from "../types/types";
import { API_URL } from "../utils/config";

export class ParseRecipientError extends Error {
  public response: ExceptionResponse;
  constructor(exceptionResponse: ExceptionResponse) {
    super("Parse recipient error");
    this.response = exceptionResponse;
  }
}

export const createContactRequest = (
  answers: Answer[],
): RecipientRequest => {
  return { answers: answers.map(it => it.questionId) };
}

export const parseRecipient = (
  answers: Answer[],
): Promise<string> => {
  const requestPayload = createContactRequest(answers);

  return fetch(`${API_URL}/recipient`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestPayload),
  }).then(async (response) => {
    if (!response.ok) {
      throw new ParseRecipientError((await response.json()) as ExceptionResponse);
    }
    const data: RecipientResponse = await response.json();
    return data.recipient;
  }).catch((error) => {
    if (error instanceof ParseRecipientError) {
      throw error;
    } else {
      throw new Error("Network error");
    }
  });
};
