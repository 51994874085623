import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { visuallyHidden, focusOutline } from "../../utils/accessibility";
import { Device } from "../../utils/breakpoints";
import Color from "../../utils/colors";
import { ExternalLink } from "../ExternalLink";
import { Navigation } from "../Navigation";

type AppContainerProps = {
  children?: React.ReactNode;
};

export const AppContainer = ({
  children,
}: AppContainerProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <SkipToContentLink href="#main-content">
          {t("skip-to-content")}
        </SkipToContentLink>
        <Navigation />
      </Header>
      <MainContent id="#main-content">{children}</MainContent>
      <Footer>
        <Span>{t("privacy-policy")}: </Span>
        <ExternalLink href={t("business-finland-privacy-policy")}>{t("business-finland")}</ExternalLink>
        <Span>, </Span>
        <ExternalLink href={t("ely-keskukset-privacy-policy")}>{t("ely-keskukset")}</ExternalLink>
        <Span>, </Span>
        <ExternalLink href={t("finnvera-privacy-policy")}>{t("finnvera")}</ExternalLink>
        <Span>, </Span>
        <ExternalLink href={t("te-toimistot-privacy-policy")}>{t("te-toimistot")}</ExternalLink>
        <Span>, </Span>
        <ExternalLink href={t("ulkoministerio-privacy-policy")}>{t("ulkoministerio")}</ExternalLink>
      </Footer>
    </Wrapper>
  );
};

const whiteBackground = css`
  background-color: #fff;
  box-sizing: border-box;
  max-width: 770px;
  padding: 0 20px;
  width: 100%;

  @media (${Device.Medium}) {
    padding-right: 65px;
    padding-left: 65px;
  }
`;

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.header`
  ${whiteBackground};
`;

const SkipToContentLink = styled.a`
  ${focusOutline}

  color: ${Color.Blue};
  position: absolute;
  right: 10px;
  text-decoration: none;
  top: 10px;

  &:not(:focus):not(:active) {
    ${visuallyHidden}
  }
`;

const MainContent = styled.main`
  ${whiteBackground}
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-grow: 1;
`;

const Span = styled.span`
`;

const Footer = styled.footer`
  background: #f1f3f4;
  width: 100%;
  text-align: center;
  border-top: 1px solid ${Color.DarkGrey};
  padding: 30px 0px 30px 0px;
`;
