import React from "react";
import styled from "styled-components";
import Color from "../../utils/colors";

type StepsProps = {
  currentPageNumber: number;
  totalPages: number;
};

export const Steps = ({
  currentPageNumber,
  totalPages,
}: StepsProps): React.ReactElement => {
  return (
    <StepsWrapper>
      {[...Array(totalPages)].map((_, i) => (
        <Step
          key={i}
          className={currentPageNumber === i + 1 ? "current" : undefined}
        />
      ))}
    </StepsWrapper>
  );
};

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Step = styled.div`
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border: 2px solid ${Color.DarkGrey};
  border-radius: 100%;
  margin: 0 10px;

  &.current {
    border-color: ${Color.Blue};
    background-color: ${Color.Blue};
  }
`;
