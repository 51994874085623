/**
 * Supported device sizes as typescript enum.
 *
 * Usage:
 * @media (${Device.Medium}) {
 *  ...
 * }
 */
export enum Device {
  ExtraSmall = "max-width: 575.98px",
  Small = "min-width: 576px",
  Medium = "min-width: 768px",
  Large = "min-width: 992px",
  ExtraLarge = "min-width: 1200px",
}
